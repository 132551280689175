<template>
  <div class="footer-wrapper">
    <div class="container-wrapper">
      <div class="container-fluid">
        <!-- 公司logo -->
        <div class="footer-logo">
          <div class="font-logo font-pingfang">
            <img class="img" src="../assets/img/footer/correct.svg" />
          </div>
        </div>
        <!-- 导航 -->
        <div class="footer-nav">
          <div
            v-for="nav in navList"
            :key="nav.id"
            class="footer-col col-6 col-lg-3 col-md-6 col-xs-6"
          >
            <div class="h6 u-mb-0 footer-header font-pingfang">
              {{ nav.name }}
            </div>
            <div class="footer-item">
              <div
                class="footer-link"
                v-for="navChild in nav.children"
                :key="navChild.id"
              >
                <div
                  class="footer-title title-underline font-pingfang"
                  @click="changleRoute(navChild.path)"
                >
                  {{ navChild.name }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="footer-col col-6 col-lg-3 col-md-6 col-xs-6 font-pingfang"
          >
            <div class="h6 u-mb-0 footer-header">联系</div>
            <div class="footer-item concat-content">
              <div href="/about" class="footer-link">
                <div class="footer-link">
                  <div class="footer-title flex-row-wrap">
                    <div>手机：</div>
                    <div class="num col-12 col-md-12 col-lg-8">
                      +8615669320127
                    </div>
                  </div>
                </div>
              </div>
              <div href="/about" class="footer-link">
                <div class="footer-link">
                  <div class="footer-title flex-row-wrap">
                    <div>Email：</div>
                    <div class="num col-12 col-md-12 col-lg-9">
                      13143000123@163.com
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 线条 -->
        <div class="footer-line line"></div>
        <!-- 版权 -->
        <div class="footer-copyright">
          <div class="title-left font-pingfang">
            <div class="title">隐私政策</div>
            <div class="title">2024CORRECT</div>
            <div class="title">保留所有权利</div>
          </div>
          <div class="title-right">
            <div class="img">
              <img src="../assets/img/footer/redbook.png" />
            </div>
            <div class="img"><img src="../assets/img/footer/tikok.png" /></div>
            <div class="img"><img src="../assets/img/footer/weback.png" /></div>
            <div class="img">
              <img src="../assets/img/footer/microblog.png" />
            </div>
            <div class="img">
              <img src="../assets/img/footer/instagram.png" />
            </div>
            <div class="img">
              <img src="../assets/img/footer/whatapp.png" />
            </div>
            <div class="img">
              <img src="../assets/img/footer/facebook.png" />
            </div>
            <div class="img"><img src="../assets/img/footer/ins.png" /></div>
            <div class="img">
              <img src="../assets/img/footer/twitter.png" />
            </div>
          </div>
        </div>
        <!-- 备案 -->
        <div class="footer-record">
          <div class="footer-title font-pingfang">
            correctdt.com 广州正解科技有限公司版权所有 2023-2024
            粤ICP备2023079055号-1
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import nav from "@/data/nav.json";
export default {
  data() {
    return {
      navList: [],
    };
  },
  mounted() {
    this.getNav();
  },
  methods: {
    getNav() {
      try {
        if (nav.code === 200) {
          this.navList = nav.data.nav;
        } else {
          this.navList = [];
        }
      } catch (e) {
        console.error(e);
      }
    },
    changleRoute(route) {
      if (this.$route.path === route) return;
      this.$router.push({ path: route });
      this.currentRoute = route;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-wrapper {
  position: relative;
  bottom: 0;
  width: 100%;
  background: #0f0f0f;
  padding-top: 5.5rem;
  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
  .footer-logo {
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    width: 19rem;
    .font-logo {
      width: 100%;
    }
  }
  .footer-nav {
    display: flex;
    flex-wrap: wrap;
    .footer-col {
      margin-bottom: 40px;
      padding-left: -24px;
      padding-right: -24px;
      .footer-header {
        color: #ffffff;
        font-size: 1.5rem;
      }
      .footer-item {
        display: flex;
        grid-column-gap: 0.5rem;
        grid-row-gap: 0.5rem;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1rem;
        .footer-link {
          .footer-title {
            color: #f6f6f6;
            height: 1.8rem;
            cursor: pointer;
          }
          .title-underline:hover {
            border-bottom: 1.5px solid;
          }
        }
      }
    }
  }
  .footer-line {
    margin-top: 1.5rem;
  }
  .line {
    background-color: #8e8e8e;
    width: 100%;
    height: 1px;
  }
  .footer-copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .title-left,
    .title-right {
      display: flex;
      justify-content: space-between;
      margin-top: 1.5rem;
      color: #fff;
    }
    .title-left {
      min-width: 350px;
    }
    .title-right {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem 2rem;
      .img {
        img {
          width: 24px;
        }
      }
    }
  }
  .footer-record {
    margin: 2.5rem 0 1rem;
    .footer-title {
      color: #fff;
    }
  }
}
@media screen and (max-width: 460px) {
  .footer-wrapper {
    .footer-logo {
      width: 50%;
    }
    .footer-copyright {
      .title-left {
        min-width: 100%;
        flex: 1;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .footer-wrapper {
    .footer-nav {
      .footer-col {
        .concat-content {
          row-gap: 1.5rem;
        }
      }
    }
  }
}
</style>
