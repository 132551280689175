<template>
  <header class="header-wrapper">
    <div class="header container-wrapper">
      <div class="header-left">
        <div class="header-left-item">
          <img class="img-logo" src="../assets/img/header-logo.png" />
        </div>
      </div>
      <div class="header-right">
        <!-- pc端 -->
        <div class="header-item" ref="navItemWrapper">
          <ul class="header-list" ref="headerList">
            <li class="haeder-nav-right">
              <span
                class="title-underline font-pingfang"
                :class="{ active: $route.path === '/Home' }"
                @click="changleRoute('/Home')"
                >首页</span
              >
            </li>
            <li class="haeder-nav-right">
              <span
                class="title-underline font-pingfang"
                :class="{ active: $route.path === '/corporation/about' }"
                @click="changleRoute('/corporation/about')"
                >关于我们</span
              >
            </li>
            <li
              class="header-nav-item-service haeder-nav-right header-dropdown-item"
              @mouseenter="handleEnterMenu"
              @mouseleave="handleOutMneu"
              @click="handleClickMenu"
            >
              <div>
                <span class="title-underline font-pingfang">服务 </span>
                <b-icon
                  class="b-down"
                  icon="chevron-down"
                  ref="bDownIcon"
                ></b-icon>
              </div>
              <ul
                class="header-dropdown-menu"
                ref="headerDropdownMenu"
                v-show="isDropMenu"
              >
                <li class="nav-dropdown-list">
                  <div
                    class="h5 nav-dropdown-title font-pingfang"
                    v-show="!isMenu"
                  >
                    {{ propDwonNav.serviceInfo.name }}
                  </div>
                  <div
                    class="child-item font-pingfang"
                    v-for="childNav in propDwonNav.serviceInfo.children"
                    :key="childNav.id"
                  >
                    <span
                      class="title-underline font-pingfang"
                      :class="{ active: $route.path === childNav.path }"
                      @click="changleRoute(childNav.path)"
                      >{{ childNav.name }}</span
                    >
                  </div>
                </li>
                <li><div class="line"></div></li>
                <li class="nav-dropdown-list">
                  <div class="h5 nav-dropdown-title font-pingfang">
                    {{ propDwonNav.technologyInfo.name }}
                  </div>
                  <div
                    class="child-item font-pingfang"
                    v-for="childNav in propDwonNav.technologyInfo.children"
                    :key="childNav.id"
                  >
                    <span
                      class="title-underline font-pingfang"
                      :class="{ active: $route.path === childNav.path }"
                      @click="changleRoute(childNav.path)"
                      >{{ childNav.name }}</span
                    >
                  </div>
                </li>
              </ul>
            </li>
            <li
              class="header-contact header-nav-item"
              @click="changleRoute('/corporation/contact')"
            >
              <div class="contact-wrapper">
                <div class="icon-wrapper">
                  <div class="icon-circle">
                    <div class="bt-icon">
                      <b-icon icon="arrow-right" variant="white"></b-icon>
                    </div>
                  </div>
                </div>
                <div class="title font-pingfang">让我们谈谈</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="menu-icon" v-show="isMenu">
          <div
            class="menu-icon-wrapper"
            :class="{ 'menu-close': isMenuIcon }"
            @click="toggle"
          >
            <div class="menu-icon-first"></div>
            <div class="menu-icon-second"></div>
            <div class="menu-icon-thrid"></div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { mapStores } from "pinia";
import useAppStore from "@/store/app";
import { BIcon, BIconArrowRight, BIconChevronDown } from "bootstrap-vue";
import nav from "@/data/nav.json";
import storage from "@/lib/js/localStorage.js";
export default {
  name: "Header",
  components: {
    BIcon,
    BIconArrowRight,
    BIconChevronDown,
  },
  data() {
    return {
      timer: null,
      screenWidth: null, // 窗口宽度
      isMenuIcon: false, // 菜单图标变化
      isMenu: false, //是否显示菜单
      isDropMenu: false, // 是否显示下拉菜单
      currentRoute: "", //当前路由
      propDwonNavList: [],
      //下拉数据
      propDwonNav: {
        technologyInfo: {}, //技术
        serviceInfo: {}, //服务
      },
    };
  },
  computed: {
    ...mapStores(useAppStore),
  },
  watch: {
    screenWidth(val) {
      // 监听窗口大小
      if (!this.timer) {
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          that.setShowMenu();
          that.appStore.detectDeviceType();
          that.timer = false;
        }, 200);
      }
    },
    // 监听路由对象
    $route(to, from) {
      // 当路由发生变化时，执行刷新主题的逻辑
      this.defaultDataTheme();
      this.$refs.navItemWrapper.style = "{}";
      this.isDropMenu = false;
      this.isMenuIcon = false;
    },
  },
  mounted() {
    this.getPropDownNav();
    this.setScreenWidth();
    this.defaultDataTheme();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    /*根据切换各种页面不同主题 */
    defaultDataTheme() {
      if (this.$route.meta.theme) {
        this.setTheme(this.$route.meta.theme);
      }
    },
    setTheme(theme) {
      document.documentElement.dataset.theme = theme;
    },
    // 获取二级导航
    getPropDownNav() {
      try {
        if (nav.success) {
          this.propDwonNav.serviceInfo = nav.data.nav[1];
          this.propDwonNav.technologyInfo = nav.data.nav[2];
        } else {
          this.propDwonNav = {};
        }
      } catch (e) {
        console.error(e);
      }
    },
    // 监听窗口大小
    setScreenWidth() {
      const _that = this;
      window.onresize = () => {
        return (() => {
          _that.screenWidth = document.body.offsetWidth;
        })();
      };
      this.screenWidth = this.screenWidth
        ? this.screenWidth
        : document.body.offsetWidth;
    },
    setShowMenu() {
      if (this.screenWidth < 992) {
        this.isMenu = true;
      } else {
        this.isMenu = false;
        this.isMenuIcon = false;
        this.$refs.navItemWrapper.style = "{}";
      }
    },

    // 移动端-菜单图标
    toggle() {
      this.isMenuIcon = !this.isMenuIcon;
      if (this.isMenuIcon) {
        this.$refs.navItemWrapper.style.height = 1500 + "px";
      } else {
        this.$refs.navItemWrapper.style.height = "0px";
        this.isDropMenu = false;
        this.bDownIcon(0);
      }
    },
    // 移入事件
    handleEnterMenu() {
      // 如果是移动端环境，阻止移入移出事件
      if (this.appStore.detectDeviceType() && this.isMenu) return;
      this.$refs.headerDropdownMenu.style = `
        opacity: 1;
        top: 3.8rem;
        transition: top 0.3s;
        visibility: visible;
      `;
      this.bDownIcon(180);
    },
    // 移出事件
    handleOutMneu() {
      if (this.appStore.detectDeviceType() && this.isMenu) return;
      this.$refs.headerDropdownMenu.style = `
        opacity: 0;
        pointer-events: none;
      `;
      this.bDownIcon(0);
    },
    handleClickMenu() {
      if (!(this.appStore.detectDeviceType() && this.isMenu)) return;
      this.isDropMenu = !this.isDropMenu;
      if (this.isDropMenu) {
        this.$refs.headerDropdownMenu.style = `
        opacity: 1;
        pointer-events: initial;
      `;
        this.bDownIcon(180);
      } else {
        this.$refs.headerDropdownMenu.style = `
         opacity: 0;
      `;
        this.bDownIcon(0);
      }
    },
    // 箭头图标
    bDownIcon(rotate) {
      this.$refs.bDownIcon.style = `
      transition: transform 0.3s ease-in-out;
              transform: rotate(${rotate}deg);`;
    },
    changleRoute(route) {
      if (this.$route.path === route) return;
      this.$router.push({ path: route });
      this.currentRoute = route;
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/lib/less/menuIcon.less";
.header-wrapper {
  min-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 995;
  // background-color:var(--theme-bg-color);
  color: var(--theme-text-color);
  .container-wrapper {
    height: 90px;
  }
  .header {
    .header-left {
      width: 20%;
      .header-left-item {
        width: 100%;
        img {
          width: 202px;
        }
      }
    }
    .header-right {
      display: flex;
      align-items: center;
      .header-item {
        .header-list {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          font-weight: bold;

          .header-nav-item {
            display: flex;
            align-items: center;
            height: 62px;
          }
          .header-nav-item-service {
            display: flex;
            align-items: center;
            height: 62px;
          }
          .haeder-nav-right {
            margin-right: 4rem;
            cursor: pointer;
          }
          .header-nav-item:last-child {
            margin-right: 0;
          }
          /* 下拉菜单 */
          .header-dropdown-item {
            position: relative;
          }

          .header-dropdown-menu {
            display: flex;
            position: absolute;
            right: -12.5rem;
            top: 5rem;
            padding: 1rem 1.5rem;
            max-height: initial;
            overflow: initial;
            pointer-events: initial;
            opacity: 0;
            transition: opacity 0.3s, top 0.3s;
            background: #fff;
            color: #000;
            box-shadow: 4px 4px 20px rgba(15, 15, 15, 0.05);
            .nav-dropdown-list {
              width: 26vw;
              max-width: 288px;
              .nav-dropdown-title {
                margin-bottom: 0.4em;
                padding: 1rem 1rem 0 1rem;
                cursor: text;
              }
              .child-item {
                padding: 0.6rem 1rem 0.6rem 1rem;
              }
            }
            .line {
              margin-right: 2rem;
              width: 1px;
              height: 100%;
              background-color: #8e8e8e;
            }
            .nav-dropdown-list:nth-child(2) {
              padding-left: 2rem;
            }
          }

          .b-down {
            width: 25px;
            transition: transform 0.3s ease-in-out;
            transform: rotate(0deg);
            color: var(--theme-text-color);
          }

          .header-contact {
            width: auto;
            height: 50px;
            padding: 0.8rem;
            border-radius: 20rem;
            border: 1px solid;
            border-color: var(--theme-border-color);
            cursor: pointer;
            .contact-wrapper {
              display: flex;
              align-items: center;
              .icon-wrapper {
                position: relative;
                width: 35px;
                .icon-circle {
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  transform-origin: left;
                  background-color: #731e8f;
                  transition: transform 0.2s ease-in-out;
                  .bt-icon {
                    position: absolute;
                    top: -2px;
                    left: 2px;
                    text-align: center;
                    transform: scale(0);
                  }
                }
              }
            }
            &:hover .icon-circle {
              transform: scale(1.5);
            }
            &:hover {
              .contact-wrapper {
                .icon-wrapper {
                  .icon-circle {
                    .bt-icon {
                      transform: scale(1);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .header-wrapper {
    // overflow:auto;
    // -webkit-overflow-scrolling: touch;
    .header {
      .header-left {
        .header-left-item {
          width: 100%;
          img {
            width: 180px;
          }
        }
      }
      .header-right {
        // font-size:1.25rem;
        .header-item {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          min-width: 200px;
          height: 0;
          overflow: hidden;
          transition: height 50ms ease 0s;
          z-index: -1;
          .header-list {
            display: flex;
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
            flex-flow: column wrap;
            justify-content: space-around;
            align-items: center;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            padding: 20px;
            background-color: #fff;
            box-shadow: 0 8px 50px rgba(0, 0, 0, 0.05);
            color: #000;
            .haeder-nav-right {
              margin-right: 0;
            }

            .header-nav-item-service {
              display: block;
              text-align: center;
              height: 25px;
              .nav-link {
                transition: none;
                .title-underline {
                  border-bottom: none;
                }
              }
            }
            .header-nav-item-service:hover {
              height: auto;
            }

            .header-dropdown-menu {
              position: static;
              display: flex;
              grid-column-gap: 0.5rem;
              grid-row-gap: 0.5rem;
              box-shadow: none;
              flex-direction: column;
              opacity: 0;
              transition: none;
              width: 83vw;
              .nav-dropdown-list {
                width: 100%;
                max-width: none;
              }
              .nav-dropdown-list:nth-child(2) {
                padding-left: 0;
              }
              .line {
                width: 100%;
                height: 1px;
              }
            }
            .b-down {
              color: #000;
            }
            .header-contact {
              border-color: #000;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .header-wrapper {
    .header {
      .header-right {
        .header-item {
          .header-list {
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
