import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/Home",
  },
  {
    path: "/Home",
    name: "Home",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      name: "首页",
      theme: "dark",
    },
  },
  {
    path: "/corporation/about",
    name: "About",
    component: () => import("@/views/About.vue"),
    meta: {
      name: "关于我们",
      theme: "dark",
    },
  },
  {
    path: "/corporation/job",
    name: "Job",
    component: () => import("@/views/Job.vue"),
    meta: {
      name: "职业机会",
      theme: "dark",
    },
  },
  {
    path: "/corporation/contact",
    name: "Contact",
    component: () => import("@/views/Contact.vue"),
    meta: {
      name: "联系我们",
      theme: "light",
    },
  },
  {
    path: "/service/policyProductDefinition",
    name: "PolicyProduct",
    component: () => import("@/views/serviceView/policyProductDefinition.vue"),
    meta: {
      name: "策略和产品定义",
      theme: "dark",
    },
  },
  {
    path: "/service/productDesign",
    name: "ProductDesign",
    component: () => import("@/views/serviceView/productDesign.vue"),
    meta: {
      name: "产品设计",
      theme: "light",
    },
  },
  {
    path: "/service/mobileDevelopment",
    name: "mobileDevelopment",
    component: () => import("@/views/serviceView/mobileApplication.vue"),
    meta: {
      name: "移动应用程序设计",
      theme: "dark",
    },
  },
  {
    path: "/service/smallProgram",
    name: "smallProgram",
    component: () => import("@/views/serviceView/smallProgram.vue"),
    meta: {
      name: "小程序",
      theme: "light",
    },
  },
  {
    path: "/service/webDevelopment",
    name: "webDevelopment",
    component: () => import("@/views/serviceView/webDevelopment.vue"),
    meta: {
      name: "web开发",
      theme: "dark",
    },
  },
  {
    path: "/service/afterSalesService",
    name: "afterSalesService",
    component: () => import("@/views/serviceView/afterSalesService.vue"),
    meta: {
      name: "售后服务",
      theme: "dark",
    },
  },
  {
    path: "/service/deployingServers",
    name: "deployingServers",
    component: () => import("@/views/serviceView/deployingServers.vue"),
    meta: {
      name: "部署服务器",
      theme: "dark",
    },
  },
  {
    path: "/technology/flutter",
    name: "flutter",
    component: () => import("@/views/technologyView/flutter.vue"),
    meta: {
      name: "Flutter",
      theme: "dark",
    },
  },
  {
    path: "/technology/backgroundManagementSystem",
    name: "backgroundManagementSystem",
    component: () =>
      import("@/views/technologyView/backgroundManagementSystem.vue"),
    meta: {
      name: "后台管理系统",
      theme: "dark",
    },
  },
  {
    path: "/technology/secondaryDevelopment",
    name: "secondaryDevelopment",
    component: () => import("@/views/technologyView/secondaryDevelopment.vue"),
    meta: {
      name: "二次开发",
      theme: "light",
    },
  },
  {
    path: "/technology/typeOfResponse",
    name: "typeOfResponse",
    component: () => import("@/views/technologyView/typeOfResponse.vue"),
    meta: {
      name: "响应式技术",
      theme: "dark",
    },
  },
  {
    path: "/technology/ARDevelopment",
    name: "ARDevelopment",
    component: () => import("@/views/technologyView/ARDevelopment.vue"),
    meta: {
      name: "AR/VR应用开发服务",
      theme: "dark",
    },
  },
  {
    path: "/:pathMatch(.*)*", // 将匹配所有内容并将其放在 `$route.params.pathMatch` 下,在没有找到页面的情况下就触发。
    name: "NotFound",
    component: () => import("../components/Four-or-four.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终在元素 #main 上方滚动 10px
    return {
      // el: document.getElementById('main'),
      // el: '#app',
      // 在元素上 10 像素
      top: 0,
    }
  },
});

export default router;
