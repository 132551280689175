<template>
  <!-- <div v-if="loading" class="loading-spinner">加载中...</div> -->
  <div id="app">
    <v-header class="navbar"></v-header>
    <div class="root-container">
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <v-footer></v-footer>
  </div>
</template>
<script>
import "@/lib/less/lenis.less";
import { mapStores } from "pinia";
import useAppStore from "@/store/app";
import Headers from "@c/Header.vue";
import Footer from "@c/Footer.vue";
import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  components: {
    "v-header": Headers,
    "v-footer": Footer,
  },
  data() {
    return {
      lenis: null,
      loading: false,
    };
  },
  computed: {
    ...mapStores(useAppStore),
  },
  mounted() {
    this.setLenis();
    this.setHeader();
    // 在路由切换前重置滚动位置
    this.$router.beforeEach((to, from, next) => {
      this.loading = true; // 开始加载
      this.lenis.scrollTo(0, 0, { duration: 0 }); // 重置到顶部
      next();
    });
  },
  destroyed() {
    this.lenis = null; // 清理
  },
  methods: {
    setLenis() {
      this.lenis = new Lenis({
        easing: (t) => t, // 插值函数
        direction: "vertical", // 滚动方向
        smooth:true, // 启用平滑滚动
        syncTouch:true
      });
      this.lenis.on("scroll", ScrollTrigger.update);
      gsap.ticker.add((time) => {
        this.lenis.raf(time * 500);
      });
      gsap.ticker.lagSmoothing(0);
    },
    setHeader() {
      let lastScrollY = 10;
      let navbar = document.querySelector(".navbar");
      window.addEventListener("scroll", () => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > lastScrollY) {
          // // 向下滚动，隐藏导航栏
          navbar.style = `top:-106px;background-color: var(--theme-bg-color)`;
        } else {
          // // 向上滚动，显示导航栏
          navbar.style = `top:0px;background-color: var(--theme-bg-color)`;
          if (currentScrollY < 50) {
            navbar.style = `background-color: transparent`;
          } 
        }
        lastScrollY = currentScrollY; // 更新上次滚动位置
      });
    },
  },
};
</script>
<style lang="less">
@import "@/lib/less/public.less";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.root-container {
  position: relative;
  z-index: 10;
}
body {
  background-color: var(--theme-bg-color) !important;
  background-color: transparent;
}
.navbar {
  transition: all 0.3s ease-in-out;
}
.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  background-color: aqua;
  color: #3498db; /* 你的主题色 */
  /* 这里可以添加炫酷的动画效果 */
}
</style>
