import {defineStore} from 'pinia'

const useAppStore = defineStore('app',{
    state:()=>{
      return{
        isMobile:null, //判断环境：移动还是pc,true:移动，false:pc端
      }
    },
    actions:{
        detectDeviceType() {
          const userAgent = navigator.userAgent;
          // 判断是否是移动端设备的一般方法
          let isMobile =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              userAgent
            );
         
          return isMobile;
        },
        changeLine(text){
          return text.replace(/n/g, "<br />");
        }
    }
  })

export default useAppStore